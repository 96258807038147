import React from 'react'
import { graphql } from 'gatsby'
import * as Component from '../components'

export default ({ data }) => {
  if (!data) return null

  return (
    <>
      <Component.Schema
        markup={{
          "name": "Hesam Bayat",
          "description": "TODO: Add page description",
        }} />
      <Component.Header fixed />
      <div className="container">
        <div className="alignwide">
          {/* <div className="section">
            <Component.Announcement
              color="#1A2AD6"
              backgroundColor="#EEF2FF"
            >
              <h2>Now hiring!!!</h2>
              <p>Front end developer <br />Graphic designer <br />Content writer <br />and more...</p>
              <a href="https://staff.am/en/jobs?JobsFilter%5Bcompany%5D=5357" className="btn" target="_blank" rel="noopener noreferrer">Apply now</a>
            </Component.Announcement>
          </div> */}
          <div className="section">
            <Component.SectionHeader title="Featured themes" label="View more themes" link="/themes" />
            <div className="row">
              {data.prismic.allThemes.edges.map(item => {
                return (
                  <div key={item.node._meta.uid} className="col-12 col-lg-6">
                    <Component.ThemeCard item={item} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="section">
            <Component.SectionHeader title="From blog" border />
            <div className="row">
              {data.prismic.allPosts.edges.map(item => {
                // console.log(item)
                return (
                  <div key={item.node._meta.uid} className="col-12 col-lg-6">
                    <Component.PostCard item={item} />
                  </div>
                )
              })}
            </div>
            {data.prismic.allPosts.totalCount > 6 && <Component.SectionFooter label="View archive" link="/more" />}
          </div>
        </div>
      </div>
      <Component.Footer fixed />
    </>
  )
}

export const query = graphql`
  query HomeQuery {
    prismic {
      allThemes (tags_in: "Featured", sortBy: meta_lastPublicationDate_DESC) {
        totalCount
        edges {
          node {
            name
            title
            price
            discounted_price
            demo_url {
              ... on PRISMIC__ExternalLink{
                url
              }
            }
            buy_url {
              ... on PRISMIC__ExternalLink{
                url
              }
            }
            image
            imageSharp {
              childImageSharp {
                fixed(width: 500, height: 239) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            _meta {
              lastPublicationDate
              uid
            }
          }
        }
      }
      allPosts {
        totalCount
        edges {
          node {
            title
            image
            imageSharp {
              childImageSharp {
                fixed(width: 120, height: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            _meta {
              lastPublicationDate
              uid
            }
          }
        }
      }
    }
  }
`